import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DatePickerWithTime from 'react-datetime';
import { Select } from 'sw-ui';
import Autocomplete from 'react-autocomplete';

import SelectCompany from '../selectCompany';
import SelectDepartment from '../selectDepartment';
import SelectProject from '../selectProject';
import AnalyticsRows from '../analyticsRows';
import EmployeeSuggest from '../../../../components/employee';
import Input from '../../../../components/input';
import SelectWithInput from '../../../../components/SelectWithInput';
import { FlatButton } from '../../../../components/button';
import Radio from '../../../../components/radio';
import { UploadVoucher } from './components/UploadVoucher';

import {
  applyAnalytics,
  removeAnalytics,
 validateAnalytics,
  validateSingleAnalytics,
} from '../../../../bi/utils/customAnalytics';
import { isMandatory } from '../../../../bi/utils/account';
import { findDepartments, preventKeyNotNumber } from '../../../../bi/utils/trip';
import { getPassportDueDate, getPassportType } from '../../../../bi/utils/passport';
import scrollToErrMessage from '../../../../utils/scrollToErrMessage';
import { formatDate } from '../../../../utils/date';
import { isBeforeDate, momentObject } from '../../../../bi/utils/formatDate';
import lodashReplaces from '../../../../bi/utils/lodashReplaces';

import { ERRORS_FOR_BUS, ERRORSFORALL } from '../../../../bi/constants/tripValidator';
import {
  FIELDS,
  LABELFIELDSRU,
  LABELS_VAT,
  PRICELABELS,
  TARIFF_TEXT,
  TRIPSLABELS,
  VAT_VALUE,
  VOUCHER_TEXT,
} from '../../../../bi/constants/trips';
import { TYPERATRATES } from '../../../../bi/constants/account';
import COMPONENTS from '../../../../bi/constants/components';
import { SERVICETYPE } from '../../../../bi/constants/serviceType';
import {
  TYPE_OF_STATION,
  INVALID_FORM_MESSAGE,
  BUS_TYPE_LUGGAGE_OPTIONS,
  CODE_OF_STATION,
  BUS_TYPE_STATION_OPTIONS,
  LABEL_VAT,
} from '../../../../bi/constants/bus';
import { DEFAULTDATE, DEFAULTTIME, FULLTIME, LOCALERU } from '../../../../constants/time';
import { BUS_ORDER_RATE, DEFAULT_SW_COMISSION } from '../../../../constants/rateTypes';
import { numbersSpaceAndSlash } from '../../../../bi/constants/regExp';

import style from '../../styles/form.module.scss';

const SERVICE_NAME = 'bus';

const MENUSTYLE = {
  position: 'absolute',
  left: 0,
  top: 51,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  backgroundColor: 'white',
  zIndex: 6,
};

const NewBusForm = ({
  addTrip = () => {},
  analytics = [],
  airService,
  bus,
  changeRate = { bus: { RateType: DEFAULT_SW_COMISSION } },
  companies = [],
  guidService,
  isEditing = false,
  isCopy = false,
  mandatoryProject,
  onConfirm,
  projects = [],
  setTripAnalytics = () => {},
  tripAnalyticsValueIds = [],
  tripService,
}) => {
  const defaultBusFile = bus.isEdit && bus.busFile && bus.busFile.length
    ? bus.busFile[0].file
    : null;
  const { bus: { RateType } } = changeRate;

  const [CompanyId, setCompanyId] = useState(0);
  const [ProjectId, setProjectId] = useState(0);
  const [UserAnalytics, setUserAnalytics] = useState([]);
  const [Employees, setEmployees] = useState(null);
  const [mandatory, setMandatory] = useState(false);
  const [busFile, setBusFile] = useState([{
    file: defaultBusFile,
    filed: false,
    uploaded: false,
    isShowFirst: !isEditing,
  }]);
  const [Departments, setDepartments] = useState([]);
  const [DepartmentId, setDepartmentId] = useState(0);
  const [EmployeeId, setEmployeeId] = useState(0);
  const [PassportId, setPassportId] = useState(0);
  const [employeesList, setEmployeesList] = useState([]);
  const [providers, setProviders] = useState([]);
  const [providerName, setProviderName] = useState({ label: '', value: null, id: 0, type: TYPERATRATES.DEFAULT });
  const [orderId, setOrderId] = useState('');
  const [ticketNumber, setTicketNumber] = useState('');
  const [seatNumber, setSeatNumber] = useState('');
  const [luggage, setLuggage] = useState('');
  const [busRoute, setBusRoute] = useState({
    from: { Departure: '', suggest: [], typeOfPoint: TYPE_OF_STATION.BUS.NAME_FOR_SERVICE },
    to: { Destination: '', suggest: [], typeOfPoint: TYPE_OF_STATION.BUS.NAME_FOR_SERVICE },
  });
  const [departureValue, setDepartureValue] = useState('');
  const [destinationValue, setDestinationValue] = useState('');
  const [checkinDate, setCheckinDate] = useState('');
  const [checkoutDate, setCheckoutDate] = useState('');
  const [price, setPrice] = useState('');
  const [commission, setCommission] = useState(BUS_ORDER_RATE[RateType]);
  const [hasVat, setHasVat] = useState(false);
  const [busLoading, setBusLoading] = useState(false);
  const [warnDates, setWarnDates] = useState('');
  const [Description, setDescription] = useState('');
  const [validation, setValidation] = useState({
    isValid: true,
    analytics: '',
    CheckinDate: '',
    CheckoutDate: '',
    commission: '',
    CompanyId: '',
    DepartmentId: '',
    Departure: '',
    Description: '',
    Destination: '',
    Employees: '',
    OrderId: '',
    PassportId: '',
    Price: '',
    ProjectId: '',
    ProviderName: '',
    Providers: '',
    SeatNumber: '',
    Luggage: '',
    TicketNumber: '',
    HasVAT: '',
    ValidFile: '',
  });

  const updateTripData = ({
    employeesList: employees,
    busFile: voucher,
    busLoading: loading,
  }) => {
    setEmployeesList(employees);
    setBusFile(voucher);
    setBusLoading(loading);
  };

  const setStateCopy = (busData) => {
    if (!busData.JsonData) return null;

    const {
      CheckinDate: CheckinDateCached,
      CheckoutDate: CheckoutDateCached,
      Departure: DepartureCached,
      DepartureStationType: DepartureStationTypeCached,
      Destination: DestinationCached,
      DestinationStationType: DestinationStationTypeCached,
      ProviderName: ProviderNameCached,
      PriceDetails: {
        Base,
        commission: CommissionCached,
        hasVat: hasVatCached,
      },
  } = busData.JsonData;

    setCheckinDate(momentObject(CheckinDateCached));
    setCheckoutDate(momentObject(CheckoutDateCached));
    setEmployeeId(null);
    setDepartureValue(DepartureCached);
    setDestinationValue(DestinationCached);
    setBusRoute({
      from: { Departure: DepartureCached, suggest: [], typeOfPoint: DepartureStationTypeCached },
      to: { Destination: DestinationCached, suggest: [], typeOfPoint: DestinationStationTypeCached },
    });
    setProviderName({ label: ProviderNameCached, value: ProviderNameCached, id: 0, type: TYPERATRATES.DEFAULT });
    setPrice(Base);
    setCommission(CommissionCached);
    setHasVat(hasVatCached);
    tripService.defaultBusFile();
    setBusFile([{
      file: null,
      filed: false,
      uploaded: false,
    }]);
  };

  const setStateEdit = (busData) => {
    const {
      EmployeeId: EmployeeIdCached,
      Employees: EmployeesCached,
      CompanyId: CompanyIdCached,
      Departments: DepartmentsCached,
      DepartmentId: DepartmentIdCached,
      PassportId: PassportIdCached,
      ProjectId: ProjectIdCached,
      UserAnalytics: UserAnalyticsCached,
      luggage: LuggageCached,
      seatNumber: SeatNumberCached,
      busRoute: BusRouteCached = {
        from: { Departure: '', suggest: [], typeOfPoint: TYPE_OF_STATION.BUS.NAME_FOR_SERVICE },
        to: { Destination: '', suggest: [], typeOfPoint: TYPE_OF_STATION.BUS.NAME_FOR_SERVICE },
      },
      checkinDate: DepartureDateCached,
      checkoutDate: DestinationDateCached,
      ticketNumber: TicketNumberCached,
      orderId: OrderIdCached,
      providerName: ProviderNameCached,
      commission: CommissionCached,
      price: PriceCached,
      hasVat: HasVatCached,
      busFile: busFileCached = [{
        file: null,
        filed: false,
        uploaded: false,
      }],
    } = busData;

    setCompanyId(CompanyIdCached);
    setEmployees(EmployeesCached);
    setEmployeeId(EmployeeIdCached);
    setDepartments(DepartmentsCached);
    setDepartmentId(DepartmentIdCached);
    setPassportId(PassportIdCached);
    setProjectId(ProjectIdCached);
    setUserAnalytics(UserAnalyticsCached);
    setCheckinDate(momentObject(DepartureDateCached));
    setCheckoutDate(momentObject(DestinationDateCached));
    setSeatNumber(SeatNumberCached);
    setOrderId(OrderIdCached);
    setProviderName(ProviderNameCached);
    setLuggage(LuggageCached);
    setTicketNumber(TicketNumberCached);
    setDepartureValue(BusRouteCached.from.Departure);
    setDestinationValue(BusRouteCached.to.Destination);
    setBusRoute(BusRouteCached);
    setPrice(PriceCached);
    setCommission(CommissionCached);
    setHasVat(HasVatCached);
    setBusFile(busFileCached);
  };

  const setStateEditInTrips = (busData) => {
    const { addBusVouchers } = tripService;

    if (!busData.JsonData || !busData) return null;

    const {
      ChatId,
      CheckinDate,
      CheckoutDate,
      Departure,
      DepartureStationType = 0,
      Destination,
      DestinationStationType = 0,
      HasLuggage,
      ProviderName,
      SeatNumber,
      TicketNumber,
      VoucherFileName,
      FileName,
      PriceDetails: { BasePrice, Commission, HasVAT },
    } = JSON.parse(busData.JsonData);

    const busVoucher = {
      file: VoucherFileName ? new File([VoucherFileName], FileName) : null,
      uploaded: !!VoucherFileName,
      guid: VoucherFileName,
    };

    addBusVouchers([busVoucher]);

    setCompanyId(busData.CompanyId || 0);
    setUserAnalytics(busData.UserAnalytics || []);
    setProjectId(busData.ProjectId || 0);
    setPrice(BasePrice);
    setProviderName({ label: ProviderName, value: ProviderName, id: 0, type: TYPERATRATES.DEFAULT });
    setOrderId(ChatId);
    setCheckinDate(momentObject(CheckinDate));
    setCheckoutDate(momentObject(CheckoutDate));
    setSeatNumber(SeatNumber);
    setTicketNumber(TicketNumber);
    setLuggage(String(HasLuggage));
    setEmployees(busData.Employees[0] || { Id: null, Documents: [{ Id: null }] });
    setEmployeeId(busData.Employees[0].Id);

    const isExsitsDocuments = busData.Employees[0].Documents.length;

    if (isExsitsDocuments) { setPassportId(busData.Employees[0].Documents[0].Id || null); }

    setDepartureValue(Departure);
    setDestinationValue(Destination);
    setBusRoute({
      from: { Departure, suggest: [], typeOfPoint: TYPE_OF_STATION[CODE_OF_STATION[DepartureStationType]].NAME_FOR_SERVICE },
      to: { Destination, suggest: [], typeOfPoint: TYPE_OF_STATION[CODE_OF_STATION[DestinationStationType]].NAME_FOR_SERVICE },
    });
    setCommission(Commission);
    setHasVat(String(HasVAT));
    const dataFromTripService = tripService.get();

    setBusFile(() => ([
      { ...dataFromTripService.busFile[0] },
    ]));
  };

  useEffect(() => {
    const unsubscribe = tripService.subscribe(updateTripData);

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (bus.isEdit) {
      return setStateEdit(bus);
    }

    if (isCopy) {
      return setStateCopy(bus);
    }

    if (isEditing) {
      return setStateEditInTrips(bus);
    }
  }, [isCopy, bus]);

  const getProviders = async () => {
    try {
      const result = await tripService.getAllProviders(SERVICE_NAME);

      if (!result.length) throw new Error();

      setProviders(result);
    } catch (error) {
      setValidation({ ...validation, Providers: ERRORS_FOR_BUS.NO_PROVIDERS });
    }
  };

  useEffect(() => { getProviders(); }, []);

  const handleSelectCompany = ({ id }) => {
    const result = tripService.busTripFieldValidation(FIELDS.COMPANYID, id);

    setCompanyId(id);
    setEmployees({});
    setMandatory(isMandatory(mandatoryProject, id));
    setValidation(prev => ({
      ...prev,
      CompanyId: result,
      Employees: '',
    }));
  };

  const handleSelectDepartment = ({ id }) => {
    const result = Departments.length ? tripService.busTripFieldValidation(FIELDS.DEPARTMENTID, id) : '';

    setDepartmentId(id);
    setValidation(prev => ({
      ...prev,
      DepartmentId: result,
    }));
  };

  const handleSelectProject = ({ id }) => {
    const result = mandatory ? tripService.busTripFieldValidation(FIELDS.PROJECTID, id) : '';

    setProjectId(id);
    setValidation(prev => ({
      ...prev,
      ProjectId: result,
    }));
  };

  const handleSelectAnalytics = ({ analytics: currentAnalytics = {}, analyticsValueId = [] }) => {
    const { ApplyToTrip } = currentAnalytics;
    const analyticsValues = ApplyToTrip ? tripAnalyticsValueIds : UserAnalytics;

    const updatedAnalyticsValues = !analyticsValueId
      ? removeAnalytics(analyticsValues, currentAnalytics)
      : applyAnalytics(analyticsValueId, analyticsValues, currentAnalytics);

    const analyticsValidation = validateSingleAnalytics(updatedAnalyticsValues, currentAnalytics);

    if (ApplyToTrip) {
      setTripAnalytics(updatedAnalyticsValues);
    }

    setUserAnalytics(updatedAnalyticsValues);

    setValidation(prev => ({
      ...prev,
      analytics: {
        ...validation.analytics,
        ...analyticsValidation,
      },
    }));
  };

  const handleRemoveEmployee = () => {
    setEmployees({});
    setDepartments([]);
    setDepartmentId(0);
  };

  const handleSelectEmployee = async ({ Id }) => {
    if (!Id) return null;

    setPassportId(0);

    const currentItem = await tripService.getEmployeeDataByID(Id);

    const result = tripService.busTripFieldValidation(FIELDS.EMPLOYEES, currentItem);

    if (currentItem.Documents.length) {
      setPassportId(currentItem.Documents[0].Id);
    }

    setEmployees(currentItem);
    setDepartments(findDepartments(currentItem, CompanyId));
    setEmployeeId(currentItem.Id);
    setValidation(prev => ({
      ...prev,
      EmployeesList: result,
    }));
  };

  const handleInputChange = (e, field) => {
    switch (field) {
      case FIELDS.TICKET_NUMBER:
        setTicketNumber(e.target.value);
        break;
      case FIELDS.SEAT_NUMBER:
        setSeatNumber(e.target.value);
        break;
      case FIELDS.ORDER_ID: {
        if (numbersSpaceAndSlash.test(e.target.value) || e.target.value === '') {
          setOrderId(e.target.value);
        }
        break;
      }
      case FIELDS.CHECKIN_DATE:
        setCheckinDate(e);
        break;
      case FIELDS.CHECKOUT_DATE:
        setCheckoutDate(e);
        break;
      case FIELDS.PRICE:
        setPrice(e.target.value);
        break;
      case FIELDS.DESCRIPTION:
        setDescription(e.target.value);
        break;
      case FIELDS.COMMISSION:
        setCommission(e.target.value);
        break;
    }
    setValidation(prevState => ({ ...prevState, [field]: '' }));
  };

  const handleSelectChange = ({ value }) => {
    setLuggage(value);
    setValidation(prevState => ({ ...prevState, Luggage: '' }));
  };

  const handleRadioChange = (field, e) => {
    setHasVat(String(e));
    setValidation(prevState => ({ ...prevState, [field]: '' }));
  };

  const handleEmployeeFocus = async () => {
    await tripService.autocompleteEmployees(CompanyId, '');

    setValidation(prev => ({
      ...prev,
      EmployeesList: +CompanyId ? '' : ERRORSFORALL.FIRSTSELECTCOMPANY,
    }));
  };

  const handleChangeEmployee = value => tripService.autocompleteEmployees(CompanyId, value);

  const validationDates = () => {
    if (checkoutDate && checkinDate) {
      const isValid = isBeforeDate(checkinDate, checkoutDate);

      setWarnDates(!isValid ? ERRORS_FOR_BUS.DESTINATION_DATE_EARLIER_THAN_DEPARTURE_DATE : '');
    }
    const checkinTime = tripService.busTripFieldValidation(FIELDS.CHECKIN_DATE, checkinDate);
    const checkoutTime = tripService.busTripFieldValidation(FIELDS.CHECKOUT_DATE, checkoutDate);

    setValidation(prevState => ({ ...prevState, CheckinDate: checkinTime, CheckoutDate: checkoutTime }));
  };

  const defineWarnEditChangesMessage = () => {
    const messageWarnEdit = tripService.busTripFieldValidation(FIELDS.DESCRIPTION, Description);
    setValidation(prev => ({ ...prev, Description: messageWarnEdit }));
  };

  const handleSelectProvider = (option) => {
    const { label, type } = option;

    if (isEditing && label === '') {
      return;
    }

    const res = tripService.customTripFieldValidation(FIELDS.PROVIDERNAME, label);
    setValidation({ ...validation, ProviderName: res });

    const newOption = { ...option, value: label, id: providers.length + 1 };
    const addOption = type === TYPERATRATES.OPTIONAL ? newOption : option;

    setProviderName(addOption);
  };

  const handleSelectAutocomplete = (value, item, fieldStation) => {
    const { name } = item;

    if (fieldStation === FIELDS.FROM) return setDepartureValue(name);

    return setDestinationValue(name);
  };

  const defineSuggesstArr = async (type, value) => {
    const { autocompleteBusStation, autocompleteTrainStation } = tripService;
    const { autocomplete } = airService;

    switch (type) {
      case TYPE_OF_STATION.AIRPORT.NAME_FOR_SERVICE: {
        const arr = await autocomplete(value);

        return arr.flatMap(({ LocationID, Code, City, ChildLocation }) => {
          const childLocation = ChildLocation
          ? ChildLocation.map(airport => ({ name: `${airport.Code}-${airport.City}`, key: airport.LocationID }))
          : [];

          return [{ name: `${Code}-${City}`, key: LocationID }, ...childLocation];
        });
      }

      case TYPE_OF_STATION.RAILWAY.NAME_FOR_SERVICE: {
        const arr = await autocompleteTrainStation(value);

        return arr.map(({ Name, Hint, BaseCode }, index) => ({ name: `${Name}-${Hint}`, key: BaseCode + index }));
      }

      case TYPE_OF_STATION.BUS.NAME_FOR_SERVICE: {
        const arr = await autocompleteBusStation(value);

        return arr.map(({ name, yandex_code }) => ({ name, key: yandex_code }));
      }

      default: return [];
    }
  };

  const handleChangeAutocomplete = async (value, field, pathJson) => {
    const pointDest = busRoute[field];

    if (field === FIELDS.FROM) setDepartureValue(value);

    if (field === FIELDS.TO) setDestinationValue(value);

    setValidation(prev => ({
      ...prev,
      [pathJson]: '',
    }));

    const arrSuggest = await defineSuggesstArr(busRoute[field].typeOfPoint, value) || [];

    setBusRoute(prev => ({ ...prev, [field]: { ...pointDest, suggest: arrSuggest } }));
  };

  const handleChangeSelect = (value, fieldStation, pathJson) => {
    const pointDest = busRoute[fieldStation];

    if (fieldStation === FIELDS.FROM) setDepartureValue('');

    if (fieldStation === FIELDS.TO) setDestinationValue('');

    setBusRoute(prev => ({ ...prev, [fieldStation]: { ...pointDest, typeOfPoint: value, [pathJson]: '' } }));
  };

  const uploadFile = (file, ind) => {
    const { uploadBusFile } = tripService;

    setValidation(prev => ({
      ...prev,
      ValidFile: '',
    }));

    uploadBusFile(file, ind);
  };

  const handleAddNewVoucher = () => {
    const { addBusVoucher } = tripService;

    addBusVoucher();
  };

  const handleSendVouchers = async () => {
    const { uploadBusFiles } = tripService;

    const files = busFile.filter(({ file }) => !!file).map(({ file }) => file);

    if (busFile[0].guid) return busFile[0].guid;

    if (!files.length) return '';

    const fileId = await uploadBusFiles(files);

    return fileId;
  };

  const validationSubmit = () => {
    const companyId = tripService.busTripFieldValidation(FIELDS.COMPANYID, CompanyId);
    const departmentId = Departments.length ? tripService.busTripFieldValidation(FIELDS.DEPARTMENTID, DepartmentId) : '';
    const projectId = mandatory ? tripService.busTripFieldValidation(FIELDS.PROJECTID, ProjectId) : '';
    const employees = tripService.busTripFieldValidation(FIELDS.EMPLOYEES, Employees);
    const userAnalytics = validateAnalytics(tripAnalyticsValueIds, UserAnalytics, analytics);
    const hasUnsetRequiredUserAnalytics = Object.keys(userAnalytics).some(key => !!userAnalytics[key]);
    const passportId = tripService.busTripFieldValidation(FIELDS.PASSPORT_ID, PassportId);
    const orderChatId = tripService.busTripFieldValidation(FIELDS.ORDER_ID, orderId);
    const providerValid = tripService.busTripFieldValidation(FIELDS.PROVIDERNAME, providerName.label);
    const numberTickets = tripService.busTripFieldValidation(FIELDS.TICKET_NUMBER, ticketNumber);
    const numberSeat = tripService.busTripFieldValidation(FIELDS.SEAT_NUMBER, seatNumber);
    const luggageType = tripService.busTripFieldValidation(FIELDS.LUGGAGE, luggage);
    const departure = tripService.busTripFieldValidation(FIELDS.DEPARTURE, departureValue);
    const destination = tripService.busTripFieldValidation(FIELDS.DESTINATION, destinationValue);
    const checkinTime = tripService.busTripFieldValidation(FIELDS.CHECKIN_DATE, checkinDate);
    const checkoutTime = tripService.busTripFieldValidation(FIELDS.CHECKOUT_DATE, checkoutDate);
    const priceBus = tripService.busTripFieldValidation(FIELDS.PRICE, price);
    const commissionValidation = tripService.busTripFieldValidation(FIELDS.COMMISSION, commission);
    const vat = tripService.busTripFieldValidation(FIELDS.HASVAT, hasVat);
    const validFile = tripService.busTripFieldValidation(FIELDS.VALID_FILE, busFile[0].file);
    const descriptionChanges = isEditing && tripService.busTripFieldValidation(FIELDS.DESCRIPTION, Description);

    if (
      companyId ||
      departmentId ||
      projectId ||
      employees ||
      passportId ||
      orderChatId ||
      providerValid ||
      numberTickets ||
      hasUnsetRequiredUserAnalytics ||
      numberSeat ||
      luggageType ||
      departure ||
      destination ||
      checkinTime ||
      checkoutTime ||
      priceBus ||
      vat ||
      commissionValidation ||
      validFile ||
      descriptionChanges
    ) {
      return {
        isValid: false,
        validation: {
          ...validation,
          CompanyId: companyId,
          ProjectId: projectId,
          DepartmentId: departmentId,
          Employees: employees,
          analytics: userAnalytics,
          OrderId: orderChatId,
          PassportId: passportId,
          ProviderName: providerValid,
          Price: priceBus,
          Luggage: luggageType,
          TicketNumber: numberTickets,
          SeatNumber: numberSeat,
          isValid: false,
          Departure: departure,
          Destination: destination,
          CheckinDate: checkinTime,
          CheckoutDate: checkoutTime,
          commission: commissionValidation,
          HasVAT: vat,
          ValidFile: validFile,
          Description: descriptionChanges,
        },
      };
    }

    return {
      isValid: true,
    };
  };

  const handleConfirm = async () => {
    const { isValid, validation: validationErrors } = validationSubmit();

    if (isValid) {
      busRoute.from.Departure = departureValue;
      busRoute.to.Destination = destinationValue;

      const Guid = guidService.generate();

      const state = {
        Employees,
        CompanyId,
        ProjectId,
        UserAnalytics,
        Departments,
        DepartmentId,
        EmployeeId,
        PassportId,
        employeesList,
        ticketNumber,
        DepartureStationType: busRoute.from.typeOfPoint,
        DestinationStationType: busRoute.to.typeOfPoint,
        commission,
        price,
        mandatory,
        busRoute,
        luggage,
        providerName,
        orderId,
        seatNumber,
        checkinDate,
        checkoutDate,
        hasVat,
        ServiceType: SERVICETYPE.BUS,
      };
      const stateToSave = JSON.parse(JSON.stringify(state));
      stateToSave.Guid = Guid;
      stateToSave.busFile = busFile;

      const JsonData = {
        OrderTripItemId: bus.OrderTripItemId || 0,
        ServiceType: SERVICETYPE.BUS,
        ProviderName: providerName.label,
        Departure: busRoute.from.Departure,
        Destination: busRoute.to.Destination,
        DepartureStationType: busRoute.from.typeOfPoint,
        DestinationStationType: busRoute.to.typeOfPoint,
        SeatNumber: seatNumber,
        CheckinDate: formatDate(checkinDate, FULLTIME),
        CheckoutDate: formatDate(checkoutDate, FULLTIME),
        EmployeeIds: [EmployeeId],
        ChatId: orderId,
        TicketNumber: ticketNumber,
        VoucherFileName: await handleSendVouchers(),
        PriceDetails: {
          Base: price,
          commission: commission || 0,
          hasVat,
          VAT: 0,
        },
        HasLuggage: luggage,
      };

      const item = {
        OrderTripItemId: bus.OrderTripItemId || 0,
        OrderTripId: 0,
        CompanyId: parseInt(CompanyId, 10),
        ProjectId: parseInt(ProjectId, 10),
        DepartmentId: parseInt(DepartmentId, 10),
        EmployeeList: Employees,
        Description,
        Status: isEditing ? 1 : 0,
        ServiceType: SERVICETYPE.BUS,
        UserAnalytics,
        Guid,
        JsonData: JSON.stringify(JsonData),
        PriceDetails: {
          Base: price,
          commission: commission || 0,
          hasVat: luggage,
          VAT: 0,
        },
        indexEdited: bus.indexEdited,
      };

      addTrip(stateToSave);
      onConfirm(item);
    } else {
      scrollToErrMessage();
      setValidation(prev => ({
        ...prev,
        ...validationErrors,
      }));
    }
  };

  const renderProvider = () => {
    const providersListAndInput = [...providers, { id: 0, type: TYPERATRATES.OPTIONAL, value: null, label: '' }];

    return (
      <SelectWithInput
        label={ LABELFIELDSRU.PROVIDERS }
        items={ providersListAndInput }
        currentItem={ providerName }
        onChange={ handleSelectProvider }
        typeInput={ COMPONENTS.INPUT.TYPE.TEXT }
        valid={ validation.ProviderName || validation.Providers }
        onScroll
      />
    );
  };

  const renderPassport = () => {
    let selectHtml = <span className='error-msg'>{ VOUCHER_TEXT.HAS_NOT_PASSPORT }</span>;

    if (Employees && Employees.Documents && Employees.Documents.length) {
      const passportHtml = Employees.Documents.map((passport, index) => {
        const passportType = getPassportType(Employees.Code, passport.Type);
        const passportDueDate = getPassportDueDate(passport.DueDate);
        const passportDueDateHtml = passportDueDate ? `${TRIPSLABELS.UNTIL} ${passportDueDate}` : '';

        return (
          <option
            key={ `${passport.Id}_${index}` }
            value={ passport.Id }
          >
            {passportType} - {passport.Number} {passportDueDateHtml}
          </option>
        );
      });

      selectHtml = (
        <select
          value={ PassportId }
          onChange={ e => setPassportId(e.target.value) }
        >
          { passportHtml }
        </select>
      );
    }

    return (
      <div>
        <label>{ VOUCHER_TEXT.PASSPORT }</label>
        { selectHtml }
      </div>
    );
  };

  const renderValidLuggageType = () => validation.Luggage ? (
    <span className={ 'error-msg' }>{ validation.Luggage }</span>
  ) : null;

  const renderLuggage = () => (
    <div className={ style['col-1-3'] }>
      <div className={ style.select }>
        <label>{ TRIPSLABELS.LUGGAGE_TYPE }</label>
        <div className={ style.wrap }>
          <Select
            items={ BUS_TYPE_LUGGAGE_OPTIONS }
            value={ luggage }
            theme={ COMPONENTS.SELECT.THEME.BORDER }
            onChange={ handleSelectChange }
          />
        </div>
        { renderValidLuggageType() }
      </div>
    </div>
  );

  const renderAutocomplete = (title, fieldStation, pathJson) => {
    const valid = lodashReplaces.getValueInObjByPath(validation, pathJson);
    const inpValue = fieldStation === FIELDS.FROM ? departureValue : destinationValue;
    const options = BUS_TYPE_STATION_OPTIONS.map(({ value, label }) => (<option value={ value }>{ label }</option>));

    const renderTypeOfStation = () => (
      <select
        value={ busRoute[fieldStation].typeOfPoint }
        onChange={ ({ target: { value } }) => handleChangeSelect(value, fieldStation, pathJson) }
      >
        {options}
      </select>
  );

    const renderRoute = () => (
      <Autocomplete
        menuStyle={ MENUSTYLE }
        value={ inpValue }
        items={ busRoute[fieldStation].suggest }
        getItemValue={ item => item.name }
        onSelect={ (value, item) => handleSelectAutocomplete(value, item, fieldStation, pathJson) }
        onChange={ ({ target: { value } }) => handleChangeAutocomplete(value, fieldStation, pathJson) }
        renderItem={ item =>
        (
          <div className={ style['autocomplete-item'] } key={ item.key }>
            { item.name }
          </div>
        ) }
      />
    );

    const messageHtml = valid &&
      <span className={ style['error-msg'] }>{ valid }</span>;

    return (
      <div className={ `${style['auto-input']}` }>
        <label>{ title }</label>
        { renderTypeOfStation() }
        { renderRoute() }
        { messageHtml }
      </div>
    );
  };

  const renderChangeRateAlert = () => {
    if (!changeRate.bus) return null;

    return (
      <div className={ `${style.row} ${style['rate-alert']}` }>
        <div className={ `${style.row}` }>
          { TRIPSLABELS.USER_FEE_FOR_ORDER } { BUS_ORDER_RATE[RateType] } { TRIPSLABELS.RUB }
        </div>
      </div>
    );
  };

  const renderVatButtons = () => {
    const validVat = validation.HasVAT ? <span className='error-msg'>{ validation.HasVAT }</span> : null;

    return (
      <div className={ `${style['col-1-4']} ${style['vat-action']}` }>
        <label className={ style.row }>{ LABEL_VAT }</label>
        <Radio
          label={ LABELS_VAT.WITH }
          field={ FIELDS.HASVAT }
          checked={ hasVat === 'true' }
          value={ VAT_VALUE.WITH }
          onChange={ handleRadioChange }
        />
        <Radio
          label={ LABELS_VAT.WITHOUT }
          field={ FIELDS.HASVAT }
          checked={ hasVat === 'false' }
          value={ VAT_VALUE.WITHOUT }
          onChange={ handleRadioChange }
        />
        <div className={ `${style['col-1-4']}` }>
          { validVat }
        </div>
      </div>
    );
  };

  const renderValidReservationDateHtml = () =>
    validation.CheckinDate ? <span className='error-msg'>{ validation.CheckinDate }</span> : null;

  const renderValidCheckoutDateHtml = () =>
    validation.CheckoutDate ? <span className='error-msg'>{ validation.CheckoutDate }</span> : null;

  const renderMessage = () => validation.isValid ? '' : INVALID_FORM_MESSAGE;

  const renderWarnMessage = () => <span className={ style.errorInfo }>{ warnDates }</span>;

  const renderUploadVoucher = () => {
    const { resetBusFile } = tripService;

    return busFile.map((file, ind) => {
      const buttonAddVoucherHtml = file.file && ind === busFile.length - 1 && !file.guid ? (
        <div className={ style['col-1-4'] }>
          <FlatButton
            label={ TRIPSLABELS.ADD_NEW_VOUCHER }
            onClick={ handleAddNewVoucher }
          />
        </div>
      ) : null;

      if (file.isShowFirst === false) return null;

      return (
        <div className={ style.row } key={ ind }>
          <div className={ style['col-1-2'] }>
            <UploadVoucher
              uploadFile={ item => uploadFile(item, ind) }
              busFile={ file }
              resetBusFile={ () => resetBusFile(ind) }
              valid={ validation.ValidFile }
            />
          </div>
          { buttonAddVoucherHtml }
        </div>
      );
    }
    );
  };

  const renderEditHistory = () => {
    if (!isEditing) return null;

    return (
      <div className={ `${style.row} ${style.panel}` }>
        <h4>{ TRIPSLABELS.DESCRIPTION_OF_CHANGES }</h4>
        <div className={ style.row } >
          <Input
            field={ FIELDS.DESCRIPTION }
            value={ Description }
            label={ TRIPSLABELS.DESCRIPTION }
            onChange={ handleInputChange }
            onBlur={ defineWarnEditChangesMessage }
            valid={ validation.Description }
          />
        </div>
      </div>
    );
  };

  const labelConfirmButton = isEditing || bus.isEdit ? TRIPSLABELS.CONFIRM : TRIPSLABELS.ADD_ORDER;

  return (
    <div className={ style.wrap }>
      { renderEditHistory() }
      <div className={ style.row }>
        <div className={ `${style.row} ${style.panel}` }>
          <h4>{ VOUCHER_TEXT.TITLE }</h4>
          <div className={ style.row }>
            <SelectCompany
              currentCompanyId={ CompanyId }
              companies={ companies }
              onSelect={ handleSelectCompany }
              validationCompany={ validation.CompanyId }
            />
            <SelectDepartment
              departments={ Departments }
              onSelect={ handleSelectDepartment }
              validationDepartment={ validation.DepartmentId }
              currentDepartmentId={ DepartmentId }
              currentEmployeeId={ EmployeeId }
            />
            <SelectProject
              disabled={ !CompanyId }
              currentProjectId={ ProjectId }
              validationProject={ validation.ProjectId }
              mandatoryProject={ mandatoryProject }
              projects={ projects }
              onSelect={ handleSelectProject }
            />
          </div>
          <AnalyticsRows
            analytics={ analytics }
            serviceAnalyticsValueIds={ UserAnalytics }
            tripAnalyticsValueIds={ tripAnalyticsValueIds }
            onSelect={ handleSelectAnalytics }
            validation={ validation.analytics }
          />
          <div className={ style.row }>
            <div className={ style['col-1-3'] }>
              <EmployeeSuggest
                items={ employeesList }
                selected={ Employees }
                placeholder={ TRIPSLABELS.EMPLOYEE }
                onRemove={ handleRemoveEmployee }
                onSelect={ handleSelectEmployee }
                valid={ validation.Employees }
                onFocus={ handleEmployeeFocus }
                onChange={ handleChangeEmployee }
                disablePick={ !CompanyId }
              />
            </div>
            <div className={ style['col-1-3'] }>
              <div className={ style.select }>
                <div className={ style.wrap }>
                  { renderPassport() }
                </div>
              </div>
            </div>
          </div>
          <div className={ `${style.row}` }>
            <div className={ style['col-1-4'] } >
              <div className={ `${style.wrap} ${validation.OrderId ? style['no-valid-company'] : ''}` }>
                <Input
                  value={ orderId }
                  field={ FIELDS.ORDER_ID }
                  label={ TRIPSLABELS.ORDER_NUMBER }
                  onChange={ handleInputChange }
                  valid={ validation.OrderId }
                />
              </div>
            </div>
            <div className={ style['col-1-3'] } >
              <div className={ `${style.wrap} ${validation.ProviderName ? style['no-valid-company'] : ''}` }>
                { renderProvider() }
              </div>
            </div>
            <div className={ style['col-1-3'] } >
              <div className={ `${style.wrap}` }>
                <Input
                  value={ ticketNumber }
                  label={ VOUCHER_TEXT.TICKET }
                  field={ FIELDS.TICKET_NUMBER }
                  onChange={ handleInputChange }
                  valid={ validation.TicketNumber }
                />
              </div>
            </div>
          </div>
          <div className={ style.row }>
            <div className={ style['col-1-3'] }>
              <Input
                value={ seatNumber }
                label={ TRIPSLABELS.SEAT_NUMBER }
                field={ FIELDS.SEAT_NUMBER }
                onChange={ handleInputChange }
                valid={ validation.SeatNumber }
              />
            </div>
            { renderLuggage() }
          </div>
          <div className={ style.row }>
            <div className={ style['col-1-3'] }>
              { renderAutocomplete(TRIPSLABELS.START_PLACE, FIELDS.FROM, FIELDS.DEPARTURE) }
            </div>
            <div className={ style['col-1-3'] }>
              <DatePickerWithTime
                dateFormat={ DEFAULTDATE }
                timeFormat={ DEFAULTTIME }
                locale={ LOCALERU }
                value={ checkinDate }
                onBlur={ validationDates }
                onChange={ value => handleInputChange(value, FIELDS.CHECKIN_DATE) }
                className={ `componentHook ${validation.CheckinDate ? 'no-valid' : ''}` }
              />
              <label>{ TRIPSLABELS.TIME_OF_DEPARTURE }</label>
              { renderValidReservationDateHtml() || renderWarnMessage()}
            </div>
          </div>
          <div className={ style.row }>
            <div className={ style['col-1-3'] }>
              { renderAutocomplete(TRIPSLABELS.END_PLACE, FIELDS.TO, FIELDS.DESTINATION) }
            </div>
            <div className={ style['col-1-3'] }>
              <DatePickerWithTime
                dateFormat={ DEFAULTDATE }
                timeFormat={ DEFAULTTIME }
                locale={ LOCALERU }
                value={ checkoutDate }
                onChange={ value => handleInputChange(value, FIELDS.CHECKOUT_DATE) }
                onBlur={ validationDates }
                className={ `componentHook ${validation.CheckoutDate ? 'no-valid' : ''}` }
              />
              <label>{ TRIPSLABELS.TIME_OF_ARRIVAL }</label>
              { renderValidCheckoutDateHtml() || renderWarnMessage() }
            </div>
          </div>
        </div>
        <div className={ `${style.row} ${style.panel}` }>
          { renderChangeRateAlert() }
          <h4>{ TARIFF_TEXT.TOTAL }</h4>
          <div className={ style.row }>
            <div className={ style['col-1-3'] }>
              <Input
                value={ price }
                field={ FIELDS.PRICE }
                label={ PRICELABELS.PRICE }
                type={ COMPONENTS.INPUT.TYPE.NUMBER }
                onChange={ handleInputChange }
                valid={ validation.Price }
                onKeyDown={ preventKeyNotNumber }
              />
            </div>
            <div className={ style['col-1-3'] }>
              <Input
                value={ commission }
                label={ PRICELABELS.COMMISSION }
                field={ FIELDS.COMMISSION }
                type={ COMPONENTS.INPUT.TYPE.NUMBER }
                onChange={ handleInputChange }
                valid={ validation.commission }
                onKeyDown={ preventKeyNotNumber }
              />
            </div>
            { renderVatButtons() }
          </div>
        </div>
        { renderUploadVoucher() }
        <div className={ style.row }>
          <div className={ style['col-1-3'] }>
            <FlatButton
              loading={ busLoading }
              onClick={ handleConfirm }
              label={ labelConfirmButton }
              inValidMessage={ renderMessage() }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

NewBusForm.propTypes = {
  bus: PropTypes.object.isRequired,
  companies: PropTypes.array.isRequired,
  mandatoryProject: PropTypes.array,
  tripService: PropTypes.object.isRequired,
  airService: PropTypes.object.isRequired,
  projects: PropTypes.array,
  analytics: PropTypes.array,
  tripAnalyticsValueIds: PropTypes.array,
  guidService: PropTypes.object.isRequired,
  setTripAnalytics: PropTypes.func,
  isCopy: PropTypes.bool,
  isEditing: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  addTrip: PropTypes.func.isRequired,
  changeRate: PropTypes.object.isRequired,
};

NewBusForm.defaultProps = {
  projects: [],
  mandatoryProject: [],
  analytics: [],
  tripAnalyticsValueIds: [],
  setTripAnalytics: () => {},
  isCopy: false,
  isEditing: false,
  partEditItem: false,
};

export default NewBusForm;
