import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { formatDate } from '../../../bi/utils/formatDate';

import COMPONENTS from '../../../bi/constants/components';

import { FULLPATTERN } from '../../../constants/time';

import { FlatButton } from '../../../components/button';
import Input from '../../../components/input';
import CircularLoaders from '../../../components/loaders';

import styles from '../styles/penalty.module.css';

const LABELS = {
  CANCEL: info => `Отменить ${info} ?`,
  PENALTIES: totalPrice => `Общая сумма штрафа за ануляцию составит: ${totalPrice} руб.`,
  CONFIRM: 'Подтвердить',
  CANCELLATION: 'Отмена',
  BASEPENALTY: 'Штраф поставщика услуг',
  FAILSUMREFUND: 'Ошибка получения суммы возврата',
  FEE: 'Доп. сборы авиакомпании',
  COMMISSION: 'Сбор Smartway',
};

const FIELDS = {
  BASEPENALTY: 'basePenalty',
  FEE: 'fee',
  COMMISSION: 'commission',
};

class AirCancelForm extends Component {
  static propTypes = {
    item: PropTypes.object,
    tripService: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    accountId: PropTypes.number.isRequired,
  };

  static defaultProps = {
    item: null,
  };

  constructor(props) {
    super();
    const {
      airRefundSum: { totalRefundSum, totalPenaltySum, success, error, tickets },
      penalties: { tripItemId, basePenalty, fee, commission },
    } = props.tripService.get();

    this.state = {
      loading: true,
      tripItemId: props.item.OrderTripItemId,
      airRefundSum: {
        totalRefundSum,
        totalPenaltySum,
        success,
        error,
        tickets,
      },
      penalties: {
        tripItemId,
        basePenalty,
        fee,
        commission,
      },
    };
  }

  componentDidMount() {
    const { tripService, accountId } = this.props;
    const { tripItemId } = this.state;

    this.unsubscribe = tripService.subscribe(this.updateState);

    tripService.getRefundSumAirline(accountId, tripItemId);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  updateState = state => this.setState({
    ...state,
  });

  handleChangeInput = (index, field, value) => {
    const { penalties } = this.state;

    penalties[index][field] = value;

    this.setState({
      ...penalties,
    });
  };

  resultMessage = () => {
    const { onClose, onChange } = this.props;

    onChange();
    onClose();
  };

  handleConfirm = () => {
    const { accountId, tripService } = this.props;
    const { penalties, tripItemId } = this.state;

    this.setState({
      loading: true,
    }, () => {
      tripService.setCancellationAirlineFee(accountId, tripItemId, penalties).then(this.resultMessage);
    });
  }

  renderLoading = () => (
    <div className={ `${styles.form} ${styles.dialog}` }>
      <CircularLoaders />
    </div>
  )

  renderTickets = () => {
    const { penalties } = this.state;

    const ticketAir = penalties.map((ticket, index) => (
      <div key={ index }>
        <div className={ styles.row }>
          #{ ticket.tripItemId }
        </div>
        <div className={ styles.tickets }>
          <div className={ styles.row_ticket }>
            <Input
              type={ COMPONENTS.INPUT.TYPE.NUMBER }
              field={ FIELDS.BASEPENALTY }
              value={ ticket.basePenalty }
              label={ LABELS.BASEPENALTY }
              onChange={ (e, field, value) => this.handleChangeInput(index, field, value) }
            />
          </div>
          <div className={ styles.row_ticket }>
            <Input
              type={ COMPONENTS.INPUT.TYPE.NUMBER }
              field={ FIELDS.FEE }
              value={ ticket.fee }
              label={ LABELS.FEE }
              onChange={ (e, field, value) => this.handleChangeInput(index, field, value) }
            />
          </div>
          <div className={ styles.row_ticket }>
            <Input
              type={ COMPONENTS.INPUT.TYPE.NUMBER }
              field={ FIELDS.COMMISSION }
              value={ ticket.commission }
              label={ LABELS.COMMISSION }
              onChange={ (e, field, value) => this.handleChangeInput(index, field, value) }
            />
          </div>
        </div>
      </div>
    ));

    return (
      <div>
        { ticketAir }
      </div>
    );
  }

  render() {
    if (!this.props.item) return null;

    const { loading, airRefundSum, penalties } = this.state;
    const { item, item: { JsonData, City, CheckinDate }, onClose } = this.props;

    const totalPrice = penalties.length > 0 && penalties
      .reduce((sum, { basePenalty, fee, commission }) => (sum + basePenalty + fee + commission), 0);

    const errorMessage = airRefundSum.error || LABELS.FAILSUMREFUND;

    if (!item) return null;
    if (loading) return this.renderLoading();

    const data = JSON.parse(JsonData);

    const info =
      `Авивабилет ${data.Routes[0].Segments[0].DepartureCity} - ${City}, ${formatDate(CheckinDate, FULLPATTERN)}`;

    if (airRefundSum.success) {
      return (
        <div className={ `${styles.form} ${styles.dialog}` }>
          <div className={ styles.row }>
            { LABELS.CANCEL(info) }
          </div>
          { this.renderTickets() }
          <div className={ styles.row }>
            { LABELS.PENALTIES(totalPrice) }
          </div>
          <div className={ `${styles.row} ${styles.action}` }>
            <FlatButton label={ LABELS.CONFIRM } onClick={ this.handleConfirm } />
            <FlatButton label={ LABELS.CANCELLATION } onClick={ onClose } />
          </div>
        </div>
      );
    }

    return (
      <div className={ `${styles.form} ${styles.dialog}` }>
        <div className={ `${styles.row_error}` }>
          { errorMessage }
        </div>
      </div>
    );
  }
}

export default AirCancelForm;
