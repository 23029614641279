import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, Checkbox } from 'sw-ui';

import { AIRLINECHANGERATE, TRAINCANCELRATE } from '../../../constants/rateTypes';
import COMPONENTS from '../../../bi/constants/components';
import { SERVICETYPE } from '../../../bi/constants/serviceType';
import { CANCELAIRFIELDS, MODELS, TRIPSLABELS } from '../../../bi/constants/trips';

import { formatDataValue, formatDate } from '../../../bi/utils/formatDate';
import { parseJson, stringifyJson } from '../../../bi/utils/json';

import { mapAirVoucherToAirTickets } from '../../../bi/services/air/mapper';

import Input from '../../../components/input';

import SelectCancelAir from './form/selectCancelAir';
import CancelSegmentAir from './form/cancelSegmentAir';

import styles from '../styles/penalty.module.css';


const FULLTIMEFORMAT = 'YYYY-MM-DDTHH:mm:sszzZ';

const LABELS = {
  DESCRIPTION: 'Название для документа',
  PENALTIES_PARAMS: 'Параметры штрафа',
  PENALTIES: 'Штраф поставщика услуг',
  TAX: 'Сбор Smartway',
  CANCELORDER: 'ОТМЕНИТЬ ЗАКАЗ',
  PENALTY: 'Отмена со штрафом',
  NOT_PENALTY: 'Отмена без штрафа',
  PARAMS: 'Укажите параметры отмены',
  PARAMS_CELLATION: 'Параметры отмены',
  CANCELLATION: change => (` У пользователя есть сбор за отмену равный ${change} рублям.`),
};

const FIELDS = {
  DESCRIPTION: 'Description',
  PENALTIES: 'Penalties',
  TAX: 'Tax',
  BASE: 'Base',
  COMMISSION: 'Commission',
};

const FIELDSFORCANCELSEGMENT = {
  [FIELDS.PENALTIES]: FIELDS.BASE,
  [FIELDS.TAX]: FIELDS.COMMISSION,
};

const WIDTH = '450px';

const indexSegment = ({ Direction, DepartureDate }, index) => `${index}_${Direction}_${DepartureDate}`;

const preparedSegments = (routes) => {
  const segments = [];

  routes.forEach(({ Segments }, index) => Segments
    .forEach(segment => segments.push({
      Segment: segment,
      SegmentIndex: indexSegment(segment, index),
      Selected: false,
    })));

  return segments;
};

const preparedData = (data, list) => {
  const routes = [];

  data.Routes.forEach((route, index) => {
    const filteredSegments = route.Segments
      .filter(segment => list
        .filter(({ Selected }) => !Selected)
        .map(({ SegmentIndex }) => SegmentIndex)
        .includes(indexSegment(segment, index)));

    if (filteredSegments.length) {
      routes.push({
        ...route,
        Segments: filteredSegments,
      });
    }
  });

  return routes;
};

const preparedDate = date => (typeof date === 'string' ? formatDate(date, FULLTIMEFORMAT) : formatDataValue(date, FULLTIMEFORMAT));

const preparedChangedSegments = segments => segments.map((segment) => {
  const { DepartureDate, ArrivalDate } = segment;

  return {
    ...segment,
    ArrivalDate: preparedDate(ArrivalDate),
    DepartureDate: preparedDate(DepartureDate),
  };
});

const preparedRoutes = routes => routes.map((route) => {
  const { Segments } = route;
  const segments = preparedChangedSegments(Segments);

  return {
    ...route,
    Segments: segments,
  };
});

class AirPenaltyForm extends Component {
  static propTypes = {
    item: PropTypes.object,
    changeRate: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    toggleDialog: PropTypes.func.isRequired,
    additionalMessage: PropTypes.string.isRequired,
  };

  static defaultProps = {
    item: null,
    changeRate: null,
  };

  constructor(props) {
    super(props);

    const { item } = this.props;
    const isAir = item.ServiceType === SERVICETYPE.AIR;

    this.state = {
      penalty: {
        Penalties: 0,
        Tax: 0,
        Description: TRIPSLABELS.PENALTY_FOR_CANCELLATION,
      },
      CancelSegment: {
        Segments: [],
        Penalty: {
          Base: 0,
          Commission: 0,
        },
        Refund: {
          Base: 0,
          Tax: 0,
          Taxes: 0,
          Fee: 0,
          Commission: 0,
        },
      },
      VatDetails: isAir ? MODELS.VatDetails.map(i => ({ ...i })) : {},
      JsonData: isAir ? mapAirVoucherToAirTickets(parseJson(item.JsonData), props.item)[0] : {},
      cancelSegmentAir: '',
      segments: isAir ? preparedSegments(parseJson(item.JsonData).Routes) : {},
      allCancel: false,
      penaltyFlag: false,
      notPenaltyFlag: false,
    };
  }

  defaultDataCancel = () => {
    const { item } = this.props;
    const { penalty } = this.state;

    const { Penalties, Tax } = penalty;
    const { OrderTripItemId, ServiceType, ProviderName, UserAnalytics } = item;

    return {
      OrderTripItemId,
      Status: 2,
      Description: penalty.Description,
      ServiceType,
      Penalties: [{
        ...penalty,
        Penalties: parseFloat(Penalties, 10) || 0,
        Tax: parseFloat(Tax, 10) || 0,
      }],
      ReturnFunds: [],
      Surcharges: [],
      ProviderName,
      UserAnalytics,
    };
  };

  preparedAirCancel = () => {
    const { item } = this.props;
    const { penalty, cancelSegmentAir, segments, CancelSegment, allCancel, VatDetails, JsonData } = this.state;
    const { OrderTripItemId, ServiceType, UserAnalytics } = item;
    const routes = preparedData(JsonData, segments);

    if (cancelSegmentAir === CANCELAIRFIELDS.SEGMENTS && routes.length && !allCancel) {
      const vat = VatDetails.filter(({ Rate }) => !!Rate);
      const changedRoutes = preparedRoutes(routes);

      const json = {
        ...JsonData,
        Routes: changedRoutes,
        VatDetails: vat,
        Travellers: JsonData.Travellers.Id,
        Passports: {
          EmployeeId: JsonData.Travellers.Id,
          PassportId: typeof JsonData.Passport === 'object' ? JsonData.Passport.PassportId : JsonData.Passport,
        },
      };

      const { Base, Commission } = CancelSegment.Penalty;

      return {
        OrderTripItemId,
        Status: 1,
        Description: penalty.Description,
        ServiceType,
        JsonData: stringifyJson([json]),
        CancelSegment: {
          ...CancelSegment,
          Penalty: {
            ...CancelSegment.Penalty,
            Base: parseFloat(Base, 10) || 0,
            Commission: parseFloat(Commission, 10) || 0,
          },
        },
        Penalties: [],
        ReturnFunds: [],
        Surcharges: [],
        UserAnalytics,
      };
    }

    return this.defaultDataCancel();
  };

  handleChangeInput = (field, value, notPenaltyFlag = false, penaltyFlag = false) => {
    const { penalty, CancelSegment, CancelSegment: { Penalty } } = this.state;

    let penaltyValue = {
      ...penalty,
      [field]: value,
    };


    let penaltyCancelValue = {
      ...Penalty,
      [FIELDSFORCANCELSEGMENT[field]]: value,
    };

    if (notPenaltyFlag) {
      penaltyValue = {
        Penalties: 0,
        Tax: 0,
        [field]: '',
      };
      penaltyCancelValue = {
        ...Penalty,
        Base: 0,
        Commission: 0,
      };
    }

    this.setState({
      notPenaltyFlag,
      penaltyFlag,
      penalty: penaltyValue,
      CancelSegment: {
        ...CancelSegment,
        Penalty: penaltyCancelValue,
      },
    });
  };

  handleChangeStep = value => this.setState({
    cancelSegmentAir: value,
  });

  handleConfirm = () => {
    const { item: { ServiceType }, onChange } = this.props;

    const tripItem = ServiceType === SERVICETYPE.AIR ? this.preparedAirCancel() : this.defaultDataCancel();

    onChange(tripItem);
    this.handleChangeStep('');
  };

  handleChangePriceInput = (e, field, value) => {
    const part = field.split('.');

    const CancelSegment = { ...this.state.CancelSegment };
    CancelSegment.Refund[part[1]] = Number(value);

    this.setState({ CancelSegment });
  };

  handleChangeVatDetails = (ticketInd, rate, field, value) => {
    const newVatDetails = this.state.VatDetails.map((item) => {
      if (item.Rate === rate) {
        return {
          ...item,
          [field]: Number(value),
        };
      }
      return item;
    });

    this.setState({ VatDetails: newVatDetails });
  };

  handleChangeCheckbox = (segments, allCancel) => {
    const names = [];

    segments.forEach(({ Selected, Segment: { DepartureCity, ArrivalCity } }) => {
      if (Selected) {
        names.push(`${DepartureCity} - ${ArrivalCity}`);
      }
    });

    this.setState({
      segments,
      allCancel,
      CancelSegment: {
        ...this.state.CancelSegment,
        Segments: names,
      },
    });
  };

  validatePenalty = () => {
    const { penalty: { Penalties, Tax }, penaltyFlag } = this.state;

    if (penaltyFlag) {
      return !Penalties && !Tax;
    }

    return false;
  };

  renderButton = (flag = true) => {
    const { penaltyFlag, notPenaltyFlag } = this.state;
    const disabledValue = (flag && !notPenaltyFlag && !penaltyFlag) || this.validatePenalty();
    const warningAlert = ((!notPenaltyFlag && !penaltyFlag) && flag) ? (
      <div className={ `${styles.row} ${styles['rate-alert']}` }>
        {LABELS.PARAMS}
      </div>
    ) : null;

    return (
      <div className={ `${styles.row} ${styles.penalty_action}` }>
        <Button
          label={ LABELS.CANCELORDER }
          theme={ COMPONENTS.BUTTON.THEME.FLAT }
          onClick={ this.handleConfirm }
          disabled={ disabledValue }
        />
        {warningAlert}
      </div>
    );
  };

  renderDefault = () => {
    const { penaltyFlag, notPenaltyFlag } = this.state;
    const { item, changeRate } = this.props;
    const { Tax, Penalties } = this.state.penalty;

    const inputPenaltiesValue = notPenaltyFlag ? 0 : Penalties;
    const inputTaxValue = notPenaltyFlag ? 0 : Tax;

    let changeRateAlert = null;

    if (item.ServiceType === SERVICETYPE.AIR && changeRate && AIRLINECHANGERATE[changeRate.airline]) {
      changeRateAlert = (
        <div className={ `${styles.row} ${styles['rate-alert']}` }>
          { LABELS.CANCELLATION(AIRLINECHANGERATE[changeRate.airline]) }
        </div>
      );
    }

    if (item.ServiceType === SERVICETYPE.TRAIN && changeRate && TRAINCANCELRATE[changeRate.train]) {
      changeRateAlert = (
        <div className={ `${styles.row} ${styles['rate-alert']}` }>
          { LABELS.CANCELLATION(TRAINCANCELRATE[changeRate.train]) }
        </div>
      );
    }

    return (
      <div className={ `${styles.form}` }>
        <div className={ `${styles.row} ${styles.title}` }>
          {LABELS.PARAMS_CELLATION}
        </div>
        <div className={ styles.row }>
          <div className={ styles.penalty__cancel_checkbox }>
            <Checkbox
              theme={ COMPONENTS.CHECKBOX.THEME.MAIN }
              value={ notPenaltyFlag }
              onChange={ () => this.handleChangeInput(FIELDS.DESCRIPTION, LABELS.NOT_PENALTY, !notPenaltyFlag, false) }
            >{LABELS.NOT_PENALTY}
            </Checkbox>
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles.penalty__cancel_checkbox }>
            <Checkbox
              theme={ COMPONENTS.CHECKBOX.THEME.MAIN }
              value={ penaltyFlag }
              onChange={ () => this.handleChangeInput(FIELDS.DESCRIPTION, LABELS.PENALTY, false, !penaltyFlag) }
            >{LABELS.PENALTY}
            </Checkbox>
          </div>
        </div>
        <div className={ styles.row }>
          <Input
            field={ FIELDS.PENALTIES }
            value={ inputPenaltiesValue }
            label={ LABELS.PENALTIES }
            type={ COMPONENTS.INPUT.TYPE.NUMBER }
            onChange={ (e, field, value) => this.handleChangeInput(field, value, notPenaltyFlag, penaltyFlag) }
            disabled={ notPenaltyFlag || !penaltyFlag }
          />
        </div>
        { changeRateAlert }
        <div className={ styles.row }>
          <Input
            field={ FIELDS.TAX }
            value={ inputTaxValue }
            label={ LABELS.TAX }
            type={ COMPONENTS.INPUT.TYPE.NUMBER }
            onChange={ (e, field, value) => this.handleChangeInput(field, value, notPenaltyFlag, penaltyFlag) }
            disabled={ notPenaltyFlag || !penaltyFlag }
          />
        </div>
      </div>
    );
  };

  renderOldDefault = () => {
    const { item, changeRate } = this.props;
    const { Tax, Penalties, Description } = this.state.penalty;

    let changeRateAlert = null;

    if (item.ServiceType === SERVICETYPE.AIR && changeRate && AIRLINECHANGERATE[changeRate.airline]) {
      changeRateAlert = (
        <div className={ `${styles.row} ${styles['rate-alert']}` }>
          { LABELS.CANCELLATION(AIRLINECHANGERATE[changeRate.airline]) }
        </div>
      );
    }

    if (item.ServiceType === SERVICETYPE.TRAIN && changeRate && TRAINCANCELRATE[changeRate.train]) {
      changeRateAlert = (
        <div className={ `${styles.row} ${styles['rate-alert']}` }>
          { LABELS.CANCELLATION(TRAINCANCELRATE[changeRate.train]) }
        </div>
      );
    }

    return (
      <div className={ `${styles.form}` }>
        <div className={ `${styles.row} ${styles.title}` }>
          {LABELS.PENALTIES_PARAMS}
        </div>
        <div className={ styles.row }>
          <Input
            field={ FIELDS.DESCRIPTION }
            value={ Description }
            label={ LABELS.DESCRIPTION }
            onChange={ (e, field, value) => this.handleChangeInput(field, value) }
          />
        </div>
        <div className={ styles.row }>
          <Input
            field={ FIELDS.PENALTIES }
            value={ Penalties }
            label={ LABELS.PENALTIES }
            type={ COMPONENTS.INPUT.TYPE.NUMBER }
            onChange={ (e, field, value) => this.handleChangeInput(field, value) }
          />
        </div>
        { changeRateAlert }
        <div className={ styles.row }>
          <Input
            field={ FIELDS.TAX }
            value={ Tax }
            label={ LABELS.TAX }
            type={ COMPONENTS.INPUT.TYPE.NUMBER }
            onChange={ (e, field, value) => this.handleChangeInput(field, value) }
          />
        </div>
      </div>
    );
  };

  renderFullDefault = () => (
    <div>
      { this.renderDefault() }
      { this.renderButton() }
    </div>
  );

  renderCancelSegmentAir = () => {
    const { item } = this.props;
    const { CancelSegment, VatDetails, segments } = this.state;

    return (
      <div>
        { this.renderOldDefault() }
        <CancelSegmentAir
          item={ item }
          segments={ segments }
          cancelSegment={ CancelSegment }
          vatDetails={ VatDetails }
          onChangePrice={ this.handleChangePriceInput }
          onChangeVatDetails={ this.handleChangeVatDetails }
          onChangeCheckbox={ this.handleChangeCheckbox }
        />
        { this.renderButton(false) }
      </div>
    );
  };

  renderAir = () => {
    const { item: { JsonData } } = this.props;
    const { cancelSegmentAir } = this.state;

    if (!cancelSegmentAir.length) {
      const { Routes } = parseJson(JsonData);

      const hasRoutes = Routes.length > 1;
      const hasSegments = Routes.some(({ Segments }) => Segments.length > 1);

      return hasRoutes || hasSegments ? (
        <SelectCancelAir onChangeStep={ this.handleChangeStep } />
      ) : this.renderFullDefault();
    }

    if (cancelSegmentAir === CANCELAIRFIELDS.ALL) {
      return this.renderFullDefault();
    }

    return this.renderCancelSegmentAir();
  };

  renderAdditionalMessage = () => {
    const { additionalMessage } = this.props;

    if (!additionalMessage) return null;

    return (
      <div className={ `${styles.row} ${styles.info} ${styles.notification}` }>
        { additionalMessage }
      </div>
    );
  }

  render() {
    const { item: { ServiceType }, toggleDialog } = this.props;

    let html = this.renderFullDefault();

    if (ServiceType === SERVICETYPE.AIR) {
      html = this.renderAir();
    }

    return (
      <Dialog width={ WIDTH } onClick={ toggleDialog }>
        <div className={ styles.penalty_wrap }>
          { html }
          { this.renderAdditionalMessage() }
        </div>
      </Dialog>
    );
  }
}

export default AirPenaltyForm;
